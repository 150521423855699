/**************************************

news.scss

***************************************/
.news{
	&__container{
		margin: 0 auto;
		padding-top: 100px;
		@include media($navSize) {
			padding-top: 50px;
		}
		.marginInner{
			margin: 0 auto;
			padding-left:wCon(100,1280);
			padding-right:wCon(100,1280);
			max-width: 1080px;
			@include media($tabletSize) {
				padding-left:wCon(20,375);
				padding-right:wCon(20,375);
			}			
		}//common
		.here{
			margin-bottom: 70px;
			margin-top: wCon(10,1080);
			font-size: 12px;
			a{
				font-size: 12px;
			}
		}//common
			justify-content: space-between;
		&__inner{
			display: flex;
			flex-direction: row-reverse;
			@include media($tabletSize) {
				display: block;
			}
			&__left{
				width: wCon(150,1080);
				min-width: 150px;
				margin-right: wCon(40,1080);
				@include media($tabletSize) {
					width: 100%;
				}
				&__search{
					border-bottom: 1px solid #333333;
					margin-bottom: 30px;
					p{
						position: relative;
						font-size: 16px;
						color: #b9b9b9;
						font-weight: bold;
						&::after{
    						content: "";
    						position: absolute;
    						top: 0;
    						right: 0;
    						display: block;
    						width: 19px;
    						height: 19px;
    						background: url(../img/news/seach_icon_01.png) no-repeat;
    						background-size: contain;
						}
					}
				}
				&__category{
					@include media($tabletSize) {
						display: flex;
			 			flex-wrap: wrap;
						justify-content: space-between;
						padding-left: wCon(25,750);; 
						padding-right: wCon(25,750);; 
					}
					&__lists{
						margin-bottom: wCon(40,150);
						@include media($tabletSize) {
							width: wCon(350,750);
							margin-bottom: wCon(40,750);
						}					
						&__ttl{
							@include fontsize(18);
							font-weight: bold;
							border-bottom: 1px solid #333333;
							padding-bottom: wCon(10,150);
							margin-bottom: wCon(10,150);
							@include media($tabletSize) {
								@include fontsize(16);
								padding-bottom: wCon(45,750);
								margin-bottom: wCon(45,750);
							}
							@include media($mobileSize_s) {
								@include fontsize(14);
							}
						}
						&__items{
							li{
								@include fontsize(14);
								@include media($tabletSize) {
									padding-bottom: wCon(30,750);
								}
								a{
									@include fontsize(14);
									&:hover{
										text-decoration: underline;
									}
								}
							}
						}
					}
				}
			}
			&__right{
				width: wCon(890,1080);
				@include media($tabletSize) {
					width: 100%;
					margin-bottom: wCon(120,750);
				}
			}
		}
		&__archive{
			margin-bottom: wCon(110,1080);
			&__text{
				margin-bottom: wCon(60,1080);
				text-align: center;
				@include media($tabletSize) {
					text-align: left;
				}
				p{
					line-height: 1.8;
				}
			}
			.archiveRight {
				@include media($tabletSize) {
					margin-bottom: wCon(90,750);
				}
			 	&__lists{
			 		display: flex;
			 		flex-wrap: wrap;
					justify-content: start;
					@include media($notepcSize) {
						justify-content: space-between;
					}
					&__item{
						width: wCon(280,890);
						margin-bottom: wCon(10,280);
						margin-right: wCon(25,890);
						@include fontsize(14);
						&:nth-child(3n){
							margin-right: 0;
							@include media($tabletSize) {
								margin: 0 auto;
								margin-bottom: wCon(10,280);
							}
						}
						&:last-child{
							margin-right: 0;
							@include media($tabletSize) {
								margin: 0 auto;
								margin-bottom: wCon(10,280);
							}
						}
						@include media($notepcSize) {
							width: wCon(420,890);
							margin-bottom: wCon(50,750);
							margin-right: 0;
						}
						@include media($tabletSize) {
							width: 100%;
							max-width: 400px;
							margin: 0 auto;
							margin-bottom: wCon(10,280);
						}
						@include media($mobileSize_s) {
							max-width: 100%;
						}

						&__img{
							margin-bottom: wCon(10,280);
							a{
								display: inline-block;
								img{
									width: 100%;
									height: auto;
									max-width: 280px;
									max-height: 187px;
									object-fit: cover;
									object-position: 0 0;
									@include media($tabletSize) {
										max-width: 100%;
										max-height: auto;
									}
								}
							}
						}
						&__tagDay{
							@include fontsize(16);
							margin-bottom: wCon(5,280);
							span{
								a{
								color: #FFFFFF;
								background: #2db4a9;
								margin-right: 1rem;
								padding: 5px 10px;

								}
							}
						}
						&__ttl{
							@include fontsize(18);
							font-weight: bold;
							margin-bottom: wCon(5,280);
						}
						&__text{
							p,h1,h2,h3,h4,h5,strong{
							@include fontsize(14);
							text-align: left;
							font-weight: normal;
							padding:0;
							margin: 0;
						}
						}
					}
			 	}
				/*==============================
					画像カバー
				==============================*/
			 	.object-fit-img {
				  object-fit: cover;
				  object-position: top;
				  font-family: 'object-fit: cover; object-position: top;'
				}
				/*==============================
					ページネーション
				==============================*/
				&Links{
					max-width: 325px;
				 	margin: 0 auto;
					.wp-pagenavi{
						display: flex;
						justify-content: center;
						span, a{
							font-size: 1rem;
							min-width: 35px;
							text-align: center;
							box-sizing: border-box;
							color: #2db4a9;
    						font-weight: bold;
    						padding: 2px 10px;
    						line-height: 1.7;
    						background: #FFFFFF;
    						border: 2px solid #2db4a9;
    						margin-right: 10px;
    						&::last-child{
    							margin-right: 0;
    						}
							&.pages{
								display: none;
							}
							&.current{
								color: #FFFFFF;
								background: #2db4a9;
							}
						}
    					a:hover{
    						color: #FFFFFF;
    						background: #2db4a9;
    						transition: all 0.4s;
    					}
					}
				}
			}
		}
		&__single{
			iframe{
				width: 100%;
			}
			margin-bottom: wCon(110,1080);
			.singleRight {
				@include media($tabletSize) {
					padding-top: 20px;
				}
				&__ttl {
					position: relative;
					display: flex;
			 		flex-wrap: wrap;
					justify-content: space-between;
					align-items: baseline;
					border-bottom: 2px solid #2db4a9;
					padding-bottom: 10px;
					margin-bottom: wCon(40,890);
					&__box {
						display: flex;
						align-items: baseline;
						width: wCon(720,890);
						@include media($tabletSize) {
							display: inline-block;
							width: 100%;
							margin-bottom: 5px;
						}
						&__catch {
							@include fontsize(18);
							font-weight: bold;
							line-height: 1.6;
						}
						&__tag {
						    a{
							color: #FFFFFF;
						    background: #2db4a9;
						    margin-right: 1rem;
						    padding: 10px;
						    	
						    }
							text-align: center;
							min-width: 100px;
						    @include media($tabletSize) {
						    	max-width: 110px;
						    	margin: 0;
						    	margin-bottom: 10px;
						    }
						}						
					}
					&__day {
						width: wCon(100,890);
						min-width: 100px;
						@include media($tabletSize) {
							position: absolute;
							top: 0;
							right: 0;
							line-height: 1.8;
						}
					}
				}
				/*-----------------------------------
				 singleRight__textarea
				-----------------------------------*/
				&__textarea {
					margin-bottom: wCon(40,890);
					p {
						margin-bottom: 20px;//仮
						& + p{
						}
						&:last-child{
							margin-top: 0;
						}
					}
					// img {
					// 	width: 100%;
					// 	height: auto;
					// }
					/*-----------------------------------
					 singleRight__textarea tag
					-----------------------------------*/
					h1{
						display: inline;
						@include fontsize(24);
						color: #333333;
						background: linear-gradient(transparent 50%, rgba(45,180,169,0.3) 50%);
						padding: 0 0.5rem;
						&:after{
							content: '';
							display: inline-table;
							margin-bottom: 20px;							
						}
					}
					h2{
						display: inline-block;
						@include fontsize(16);
						color: #FFFFFF;
						background: #333333;
						font-weight: bold;
						text-align: center;
						margin-bottom: 20px;
						padding: 10px 1rem;
					}
					h3{
						display: inline-block;
						@include fontsize(16);
						color: #2db4a9;
						border-bottom: 1px solid #2db4a9;
						line-height: 1.6;
						margin-bottom: 20px;
						padding-bottom: 5px;
					}
				
					h4{

						display: inline-block;
						@include fontsize(14);
						font-weight: bold;
						border-left: 7px solid #000000;
						margin-bottom: 10px;
						padding-left: 7px;
					}
					a{
						color: #2db4a9;
						border-bottom: 1px solid #2db4a9;
					}
					h5{
						display: inline-block;
						color: #FFFFFF;
						background: #2db4a9;
						text-align: center;
						line-height: 2;
						margin-bottom: 10px;
						padding: 0 2rem;
					}
					strong{
						font-weight: bold;
					}
					.aligncenter{
						display: flex;
    					margin: 0 auto;
					}
				}
				&__btn{
					text-align: center;
					.moreBtn {
						width: 329px;
    					height: 60px;
    					line-height: 60px;
    					//padding-right: 60px;
    					box-shadow: 2px 2px 4px grey;
    					@include media($tabletSize) {
    						max-width: 240px;
    					}
    					&:after{
    						display: none;
    					}
    					&:before {
    						content: "";
    						display: inline-block;
    						width: 16px;
    						height: 20px;
    						margin: 6px 20px 0 0;
    						background: url(../img/common/common_icon_02.png) no-repeat;
    						background-size: contain;
    						vertical-align: middle;
    						transform: rotateY(180deg);
    					}
					}
				}
			}
		}
	}
}