/**************************************

mixins.scss

***************************************/
/* ---------------------------------------------------------
media queries
----------------------------------------------------------*/
@mixin media($breakPoint){
	@media screen and (max-width: $breakPoint) {
		@content;
	}
}

/* ---------------------------------------------------------
widthの横幅計算
----------------------------------------------------------*/
@function wCon($width,$parentWidth:$columnWidth) {
    @return $width / $parentWidth * 100%;
}

/* ---------------------------------------------------------
clearfix
----------------------------------------------------------*/
@mixin clearfix(){
    &::before,
    &::after{
        content: '';
        display: table;
    }
    &::after{
        clear: both;
    }
}

/* ---------------------------------------------------------
font-size
----------------------------------------------------------*/
@mixin fontsize($size: 16, $base: 16) {
  font-size: $size + px;
  font-size: ($size / $base) * 1rem;
}

/* ---------------------------------------------------------
opacity
----------------------------------------------------------*/
@mixin opa(){
    opacity: 0.8;
    transition: opacity 0.4s;
}

/* ---------------------------------------------------------
border-radius
----------------------------------------------------------*/
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}

/* ---------------------------------------------------------
transform
----------------------------------------------------------*/
/*-- translate--*/
@mixin translate($translate-x,$translate-y) {
	-webkit-transform: translate($translate-x,$translate-y);
  -moz-transform: translate($translate-x,$translate-y);
	-ms-transform: translate($translate-x,$translate-y);
	transform: translate($translate-x,$translate-y);
}

/*-- translateY--*/
@mixin translateY($translateY) {
	-webkit-transform: translateY($translateY);
  -moz-transform: translateY($translateY);
	-ms-transform: translateY($translateY);
	transform: translateY($translateY);
}

/*-- translateX--*/
@mixin translateX($translateX) {
  -webkit-transform: translateX($translateX);
  -moz-transform: translateX($translateX);
  -ms-transform: translateX($translateX);
  transform: translateX($translateX);
}

/* ---------------------------------------------------------
backface-visibility
----------------------------------------------------------*/
@mixin backface-visibility($bv) {
	-webkit-backface-visibility: ($bv);
  backface-visibility: ($bv);
}

