/**************************************

base.scss

***************************************/
/* ---------------------------------------------------------
初期設定
----------------------------------------------------------*/

/*--------------フォント---------------*/

p,h1,h2,h3,h4,h5,a,li,ul,small,dd,dt,a{
	color: #333333;
	font-family: '游ゴシック', Yu Gothic, YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "MS Pゴシック", MS PGothic, sans-serif;
	line-height: 1.7;
	font-size: 16px;
}


