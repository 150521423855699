/**************************************

contact.scss

***************************************/
.contact{
	/*---------------------
	 エラー赤字
	---------------------*/
	.error{
    	color: #ff0000;
    	margin-top: 5px;
	}
	&__container{
		margin: 0 auto;
		padding-left:wCon(100,1280);
		padding-right:wCon(100,1280);
		padding-top: 100px;
		max-width: 1080px;
		@include media($tabletSize) {
			padding-left: wCon(40,750);
			padding-right:wCon(40,750);
			padding-top: 50px;
		}
		&__inner{
			&__text{
				margin-bottom: wCon(60,1080);
				text-align: center;
				@include media($tabletSize) {
					text-align: left;
				}
				p{
					line-height: 1.8;
					span{
						color: #ff0000;
					}
					@include media($tabletSize) {
					}
				}
			}
		}
		.marginInner{
		    margin: 0 auto;
			//padding-left: 7.8125%;
			//padding-right: 7.8125%;
		    max-width: 1080px;
		}
		.here{
			margin-bottom: 70px;
			margin-top: wCon(10,1080);
			font-size: 12px;	
			a{
				font-size: 12px;
			}
		}
		form{
			margin-bottom: 55px;
		}		
		&__table{
			width: 100%;
			margin-bottom: wCon(50,982);
			tbody {
				tr{
					display: flex;
					flex-wrap: wrap;
    				justify-content: space-between;
					@include media($tabletSize) {
						//display: inline-block;
					}
					th{
						display: inline-block;
						width: wCon(267,982);
						//min-width: 200px;
						@include fontsize(18);
						font-weight: bold;
						text-align: left;
						vertical-align: text-top;
						padding-bottom: wCon(30,1080);
						padding-top: wCon(5,1080);
						.require{
							color: #ff0000;
						}
						@include media($notepcSize) {
							@include fontsize(14);
						}
						@include media($tabletSize) {
							width: 100%;
							padding-bottom: wCon(30,750);
						}
					}
					td{
						width: wCon(715,982);
						padding-bottom: wCon(25,1080);
						@include media($notepcSize) {
							@include fontsize(14);
						}
						@include media($tabletSize) {
							width: 100%;
							padding-bottom: wCon(50,750);
							input{
								width: 100%;
							}
						}
						label{
							width: 100%;
						}
						input{							
							border: 1px solid #333333;
							line-height: 2;
							box-sizing: border-box;
							//max-width: 446px;
						}
						textarea{
							width: 100%;
							max-width: 580px;
							border: 1px solid #333333;
						    min-height: 270px;
						    box-sizing: border-box;
						    &.form__inputTextarea--01{
						    	max-width: 580px;
						    }
						}
						&.postcode{
							display: flex;
							align-items: baseline;
							label{
								width: auto;
								margin-right: 10px;
								vertical-align: middle;
								button{
									line-height: 1.8;
									background: #f9f9f9;
									border-color: #333333;
								}
							}
						}
						/*-------------------
						 相談内容
						-------------------*/
						&.radio{
							display: flex;
    						flex-wrap: wrap;
    						
    						&::last-child{
    							margin-right: 0;
    						}
    						label{
    							width: auto;
    							margin-right: 1rem;
    							line-height: 2;
    						}
							input{
							margin-right: 0.4rem;
							vertical-align: text-top;
								@include media($tabletSize) {
									width: auto;
								}
							}
							.horizontal-item{
								margin-right: 10px;
								margin-left: 0;
							}
						}
						.privacy__lead{
							height: 270px;
							border: 1px solid #333333;
							padding: 1rem;
							overflow: scroll;
						    line-height: 1.4;
    						white-space: pre-line;
    						margin-bottom: 10px;
    						@include media($tabletSize) {
    							padding: 0.2rem 1rem;
    						}
						}
						.privacy__text{
							font-size: 14px;
							line-height: 1.8;
							margin-bottom: 15px;
						}
						.privacyCheck{
							width: 14px;
						}
						.mwform-checkbox-field-text{
						    line-height: 1.5;
    					}

					}
				}
			}
		}
		/*---------------------
		 CV
		---------------------*/
		.cvBtn{
			&__item{
  				position: relative;
				max-width: 329px;
				text-align: center;
  				color: #fff;
  				margin:0 auto;
  				 @include media($tabletSize) {
				    	margin-bottom: 40px;
				    }
					&::after{
						position: absolute;
   						top: 50%;
    					content: "";
    					display: inline-block;
    					width: 15px;
    					height: 10px;
    					background-image: url(../img/common/common_icon_02.png);
    					background-repeat: no-repeat;
    					background-size: contain;
    					vertical-align: middle;
    					transition: 0.3s;
  						right: 20px;
  						transform: translateY(-50%);
 						-webkit-transform: translateY(-50%);
    				}
				input[type="submit"],button{
				    display: inline-block;
				    width: 100%;
    				color: #ffffff;
    				background-color: #2db4a9;
    				font-weight: bold;
    				text-align: center;
    				vertical-align: text-bottom;
    				box-shadow: 2px 2px 4px grey;
    				box-sizing: border-box;
    				border: none;
    				cursor: pointer;
    				padding: 20px 0;
    				transition: 0.3s;
				}
				&.back{
					button{
						display: none;
					}
					&::after{
						display: none;
					}
				}
			}
		}
	}
	/*---------------------
	 確認画面
	---------------------*/
	&__confirm{
		.contact{
			&__container{
				&__table{
					tr{
						max-width: 715px;
    					margin: 0 auto;
						th{
							width: wCon(227,715);
							@include media($notepcSize) {
								@include fontsize(14);
							}
							@include media($tabletSize) {
								width: 100%;
								padding-bottom: wCon(30,750);
							}									
						}
						td{
							width: wCon(488,715);
							&.postcode{
								button{
									display: none;
								}
							}
							@include media($notepcSize) {
								@include fontsize(14);
							}
							@include media($tabletSize) {
								width: 100%;
								padding-bottom: wCon(50,750);
								input{
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
		.privacy{
			display: none;
		}
		.cvBtn{
			width: 100%;
			max-width: 715px;
			margin: 0 auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			@include media($tabletSize) {
				justify-content: center;
			}
			&__item{
				width: wCon(360,800);
				max-width: 329px;
				margin: 0;
				@include media($tabletSize) {
					width: 100%;
					margin-bottom: 20px;
					&:last-child{
						margin-bottom: 0;
					}
				}
				&.back{
					&::after{
						display: block;
    		 			background-image: url(../img/common/common_icon_02--left.png);
    		 			background-size: contain;
    		 			left: 20px;
    					width: 15px;
    					height: 10px;
					}
					button{
						display: block;
					}
				}
			}
		}
	}
	/*---------------------
	 完了画面
	---------------------*/
	&__finish{
		.contact{
			&__container{
				&__inner{
					&__text{
						p + p{
							margin-bottom: 10px;
						}
						span{
							font-weight: bold;
						}
						&__tel{
							a{
								@include fontsize(20);
								font-weight: bold;
							}
						}
					}
					&__links{
						text-align: center;
						a{
							width: 329px;
   							height: 60px;
   							line-height: 60px;
   							box-shadow: 2px 2px 4px grey;
   							box-sizing: border-box;
   						}
					}
				}
			}
		}
	}
}
