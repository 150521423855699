/**************************************

webfont.scss

***************************************/
/* ---------------------------------------------------------
webfont
----------------------------------------------------------*/
/*--  --*/
//@font-face {
//	font-family: '';
//    src:  url('../font/.woff') format('woff'),
//    url('../font/.eot') format('eot'),
//    url('../font/.ttf') format('ttf'); 
//    font-weight: normal;
//    font-style: normal;
//}

