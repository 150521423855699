/**************************************

service.scss

***************************************/

.service{
	.marginInner{
		margin: 0 auto;
		padding-left:wCon(100,1280);
		padding-right:wCon(100,1280);
		max-width: 1080px;
		@include media($tabletSize) {
			padding-left:wCon(20,375);
			padding-right:wCon(20,375);
		}
	}//common
	.here{
		margin-bottom: 70px;
		margin-top: wCon(10,1080);
		font-size: 12px;
		a{
			font-size: 12px;
		}		
	}//common
	&__main{
		background-image: url(../img/service/service_bg_01.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;	
		width: 100%;
		height: 600px;
		position: relative;
		@include media($tabletSize) {
		    height: 400px;
    		margin-bottom: 10px;
    	}
		h2{
			position: absolute;
			left: wCon(100,1280);
			bottom: wCon(200,1280);
			color:#fff;
			padding-right: wCon(97,1280);
			font-size: 52px;
			line-height: 1.3;
			letter-spacing:1.4;
			@include media($tabletSize) {
				font-size: 24px;
			}
			span{
				display: block;
				padding-bottom: 15px;
				@include media($tabletSize) {
				font-size: 12px;
				}
			}
		}
	}//main
	
	&__container{
		margin: 0 auto;
		padding-left:wCon(100,1280);
		padding-right:wCon(100,1280);
		max-width: 1080px;
		@include media($tabletSize) {
			padding-left: wCon(40,750);
			padding-right:wCon(40,750);
		}
		
		&__business{
			margin-bottom: wCon(60,1080);
			&__text{
				margin-bottom: wCon(125,1080);
				p{
					@include fontsize(20);
					line-height: 1.8;
					text-align: center;
					@include media($tabletSize) {
						@include fontsize(14);
						text-align: left;
					}
				}
			}
		}//greetings
	}
	&__business{
		margin-bottom: wCon(87,1080);
		&__inner{
			margin: 0 auto;
			padding-left:wCon(100,1280);
			padding-right:wCon(100,1280);
			width: wCon(958,1080);
			@include media($tabletSize) {
				width: 100%;
				max-width: 480px;
				padding: 0;
			}
			&__list{
				display: flex;
				justify-content: space-between;
				margin-bottom: wCon(60,630);
				@include media($tabletSize) {
					display: block;
				}
				&__left {
					width: wCon(250,958);
					@include media($tabletSize) {
						width: 100%;
						max-width: 270px;
						margin: 0 auto;
						margin-bottom: wCon(15,750);
					}
					@include media($mobileSize_s) {
						max-width: 200px;
					}
					img{
						width: 100%;
						height: auto;
					}
				}
				&__right {
					width: wCon(630,958);
					@include media($tabletSize) {
						width: 100%;
					}
					.serviceRight {
						&__ttl {
							color: #2db4a9;
							@include fontsize(26);
							font-weight: bold;
							padding-left: 3rem;
							margin-bottom: wCon(10,630);
							position: relative;
							@include media($tabletSize) {
								@include fontsize(20);
								padding-left: 2.5rem;
							}
							&.serviceRight__icon {
								&:before {
									content: "";
									position: absolute;
									top: 0;
									left: 0;
									display: block;
									width: 45px;
    								height: 30px;
									background: url(../img/top/top_icon_01.png) no-repeat;
									background-size: contain;
									@include media($tabletSize) {
										width: 34px;
										background-size: contain;
									}
								}
								&.icon--02:before {
									width: 38px;
    								height: 36px;
									background: url(../img/top/top_icon_02.png) no-repeat;
									@include media($tabletSize) {
										width: 35px;
										background-size: contain;
									}							
								}
								&.icon--03:before {
									width: 40px;
    								height: 36px;
									background: url(../img/top/top_icon_03.png) no-repeat;
									@include media($tabletSize) {
										width: 34px;
										background-size: contain;
									}								
								}
								&.icon--04:before {
									width: 40px;
    								height: 36px;
									background: url(../img/top/top_icon_04.png) no-repeat;
									@include media($tabletSize) {
										width: 34px;
										background-size: contain;
									}								
								}
							}
						}
						&__items {
							display: flex;
							flex-wrap: wrap;
							margin-bottom: wCon(20,630);
							li {
								width: wCon(290,630);
								position: relative;
								@include fontsize(20);
								font-weight: bold;
								padding-left: 18px;
								@include media($notepcSize) {
									width: 100%;
								}								
								@include media($tabletSize) {
									width: wCon(332,750);
									@include fontsize(16);
								}
								@include media($mobileSize_s) {
									width: 100%;

								}
								&:before {
									content: "●";
									line-height: 2.4;
    								@include fontsize(14);
    								position: absolute;
    								left: 0;
    								top: 0;
									color: #2db4a9;
									@include media($tabletSize) {
										line-height: 2;
										@include fontsize(12);
									}
								}
							}
						}
						&__text {
							@include fontsize(16);
							@include media($tabletSize) {
								@include fontsize(14);
							}
						}
					}
				}
			}
			/*#service01,#service02,#service03,#service04 {
				padding-top: 20px;
				margin-bottom: -20px;
			}*/
		}
		&__subsidy {
			background: #2db4a9;
			border: 2px solid #2db4a9; 
			&__ttl {
				@include fontsize(24);
				color: #FFFFFF;
				font-weight: bold;
				text-align: center;
				padding-top: wCon(10,890);
				padding-bottom: wCon(10,890);
				@include media($tabletSize) {
					@include fontsize(20);
				}
			}
			&__list {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				background: #FFFFFF;
				padding: 30px 40px;
				padding-bottom: 20px;
				@include media($tabletSize) {
					display: block;
				}
				&__item {
					margin-bottom: wCon(20,1080);
					@include media($notepcSize) {
						width: wCon(540,1080);
						margin-bottom: wCon(40,1080);
					}
					@include media($tabletSize) {
						width: 100%;
						margin-bottom: wCon(20,750);
					}
					.subsidyListItem {
						&__ttl {
							@include fontsize(20);
							font-weight: bold;
							position: relative;
							padding-left: 18px;
							margin-bottom: wCon(10,240);
							@include media($tabletSize) {
								@include fontsize(16);
								margin-bottom: wCon(10,750);
							}
							&:before {
								content: "●";
								line-height: 2.4;
    							@include fontsize(14);
    							position: absolute;
    							left: 0;
    							top: 0;
								color: #2db4a9;
							}
						}
						&__text {
							padding-left: 1rem;
						}
					}
				}
			}
		}
	}//business
}//top
