/**************************************

reset.scss

***************************************/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-style:normal;
    font-weight: normal;
}

h1,h2,h3,h4,h5,h6,td,th,p,ul,li,dt,dd {
    font-size: 1em;
}

ul li{
  list-style: none;
}

ol li{
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

article, header, footer, aside, figure, figcaption, nav, section {
  display:block;
}

body {
  line-height: 1;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img{
	vertical-align: bottom;
}

a{
  text-decoration: none; 
}

