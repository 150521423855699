/**************************************

error404.scss

***************************************/

.error404{	
	&__container{
		margin: 0 auto;
		padding-top: 100px;
		@include media($navSize) {
			padding-top: 60px;
		}
		@include media($tabletSize) {
			padding-left: wCon(40,750);
			padding-right:wCon(40,750);
		}
		.marginInner{
			margin: 0 auto;
			padding-left:wCon(100,1280);
			padding-right:wCon(100,1280);
			max-width: 1080px;
			@include media($tabletSize) {
				padding-left:wCon(20,375);
				padding-right:wCon(20,375);
			}			
		}//common
		.here{
			margin-bottom: 70px;
			margin-top: wCon(10,1080);
			font-size: 12px;
			a{
				font-size: 12px;
			}
			
		}//common
		&__notFound{
			margin-bottom: wCon(110,1080);
			text-align: center;
			&__text{
				
				margin-bottom: wCon(60,1080);
				p{
					line-height: 1.8;
					@include media($tabletSize) {
					}
				}
			}
			&__btn{
				.moreBtn {
					min-width: 329px;
    				height: 60px;
    				line-height: 60px;
    				padding-left: 40px;
    				box-shadow: 2px 2px 4px grey;
    			}
    		}

		}//greetings
	}


}//top
