/**************************************

company.scss

***************************************/

.company{
	.marginInner{
		margin: 0 auto;
		padding-left:wCon(100,1280);
		padding-right:wCon(100,1280);
		max-width: 1080px;
		@include media($tabletSize) {
			padding-left:wCon(20,375);
			padding-right:wCon(20,375);
		}
	}//common
	.here{
		margin-bottom: 70px;
		margin-top: wCon(10,1080);
		font-size: 12px;
		a{
			font-size: 12px;
		}		
	}//common
	&__main{
		background-image: url(../img/company/company_bg_01.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;	
		width: 100%;
		height: 600px;
		position: relative;
		@include media($tabletSize) {
		    height: 400px;
    		margin-bottom: 10px;
    	}
		h2{
			position: absolute;
			left: wCon(100,1280);
			bottom: wCon(200,1280);
			color:#fff;
			font-size: 52px;
			line-height: 1.3;
			letter-spacing:1.4;
			@include media($tabletSize) {
				font-size: 24px;
			}
			span{
				display: block;
				padding-bottom: 15px;
				@include media($tabletSize) {
				font-size: 12px;
				}
			}
		}
		/*			@include fontsize(22);
			position: absolute;
			
			color:#fff;
			@include media($tabletSize) {
				@include fontsize(12);
			}
			img{
				width: 100%;
				height: auto;
			}
			span{
				display: block;
				padding-top: 10px;
				
					@include media($tabletSize) {
					span{
						@include fontsize(24);
					}
				}

			}
		}*/
	}//main
	&__container{
		margin: 0 auto;
		padding-left:wCon(100,1280);
		padding-right:wCon(100,1280);
		max-width: 1080px;
		@include media($tabletSize) {
			padding-left: wCon(40,750);
			padding-right:wCon(40,750);
		}
		
		&__greetings{
			margin-bottom: wCon(60,1080);
			&__text{
				p{
					@include fontsize(20);
					line-height: 1.8;
					@include media($tabletSize) {
						@include fontsize(14);
					}
				}
			}
		}//greetings

		&__staff{
			margin-bottom: wCon(100,1080);
			&__inner{
				&__list{
					display: flex;
					justify-content: space-between;
					&:first-child{
						margin-bottom: wCon(60,1080);
						@include media($tabletSize) {
							margin-bottom: wCon(80,670);
						}
					}
					@include media($tabletSize) {
						display: block;
					}
					&__left{
						width: wCon(500,1080);
						max-width: 500px;
						img{
							width: 100%;
							height: auto;
						}
						@include media($tabletSize) {
							width: 100%;
							margin: 0 auto;
							margin-bottom: wCon(30,670);
						}
					}
					&__right{
						width: wCon(540,1080);
						@include media($tabletSize) {
							width: 100%;
							max-width: 500px;
							margin: 0 auto;
						}
						.staff__listRight{
							&__name{
								@include fontsize(22);
								font-weight: bold;
								margin-bottom: wCon(10,540);
								span{
									display: block;
									@include fontsize(18);
								}
								@include media($tabletSize) {
									@include fontsize(18);
									span{
										@include fontsize(14);
									}
								}
							}
							&__textBox{
								&__list{
									&:first-child{
										margin-bottom: wCon(20,540);
									}
									&__tit{
										color: #2db4a9;
										@include fontsize(20);
										font-weight: bold;
										position: relative;
										margin-bottom: wCon(10,540);
										&::after{
											content: '';
    										display: block;
    										height: 2px;
    										width: 300px;
    										position: absolute;
    										bottom: 0;
    										left: 0;
    										margin: 0;
    										background: #2db4a9;
										}
										@include media($tabletSize) {
											@include fontsize(16);
										}
									}
									dl{
										display: flex;
										justify-content: space-between;
										dt{
											width: wCon(100,540);
											@include fontsize(18);
										}
										dd{
											width: wCon(440,540);
											@include fontsize(18);
										}
										@include media($tabletSize) {
											dt{
												@include fontsize(14);
											}
											dd{
												@include fontsize(14);
											}
										}
									}
									p{
										@include fontsize(18);
										@include media($tabletSize) {
											@include fontsize(14);
										}
									}
								}
							}
						}
					}
				}
			}
		}//staff

		&__access{
			margin-bottom: wCon(110,1080);
			&__map {
				position: relative;
				padding-bottom: wCon(400,1080);
				height: 0;
				overflow: hidden;
				margin-bottom: wCon(10,1080);
				@include media($tabletSize) {
					padding-bottom: wCon(446,670);
				}
				iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
			&__textMap{
					@include fontsize(24);
				a{
					font-weight: bold;
				}
			}
		}
	}//access

	&__business{
		margin-bottom: wCon(87,1080);
		&__bg{
			background-color: #eaeaea;
			padding-top:wCon(55,1080);
			padding-bottom:wCon(108,1080);
		}
		&__inner{
			margin: 0 auto;
			padding-left:wCon(100,1280);
			padding-right:wCon(100,1280);
			max-width: 1080px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			@include media($tabletSize) {
				display: block;
				padding-left: wCon(40,750);
				padding-right:wCon(40,750);
			}
			&__list{
				width: wCon(520,1080);
				&:nth-child(-n+2){
					margin-bottom: wCon(40,1080);
				}
				@include media($tabletSize) {
					width: 100%;
					max-width: 520px;
					margin: 0 auto;
					margin-bottom: wCon(30,670);
				}
				img{
					width: 100%;
					height: auto;
				}
				&__textBox{
					background: #FFFFFF;
					padding: 26px 0;
					dl{
						width: wCon(480,520);
						display: flex;
						justify-content: space-between;
						border-bottom: 1px solid #a0a0a0;
						padding-bottom: 10px;
						margin: 0 auto;
						&:nth-child(n+2){
							padding-top: 8px;
    						padding-bottom: 7px;
						}
						dt{
							width: wCon(250,480);
							@include fontsize(22);
							font-weight: bold;
							@include media($pcSize) {
								@include fontsize(20);
							}
							@include media($notepcSize) {
								@include fontsize(18);
							}
						}
						dd{
							width: wCon(230,480);
							@include fontsize(20);
							@include media($pcSize) {
								@include fontsize(18);
							}
							@include media($notepcSize) {
								@include fontsize(16);
							}
						}
						@include media($tabletSize) {
							dt{
								@include fontsize(16);
								width: wCon(220,620);
							}
							dd{
								@include fontsize(14);
								width: wCon(400,620);
							}
						}
					}
				}
			}
		}
	}//business
}//top
