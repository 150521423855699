/**************************************

top.scss

***************************************/

.top{
	&__main{
		background-image: url(../img/top/top_bg_01.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;	
		width: 100%;
		height: 800px;
		position: relative;
		margin-bottom: 80px;
		@include media($tabletSize) {
			height: 472px;
			margin-bottom: 65px;
		}
		h2{
			position: absolute;
			left: wCon(100,1280);
			bottom: wCon(280,1280);
			color:#fff;
			padding-right: wCon(100,1280);
			img{
				width: 100%;
				height: auto;
			}
			span{
				display: block;
				padding-top: 10px;
				@include media($tabletSize) {
				font-size: 12px;
			}
			}
			
		}
		.scrollBtn{
			position: absolute;
			bottom: -50px;
			left: 0;
			right: 0;
			margin: auto;
			@include media($tabletSize) {
				width: 13px;
				height: auto;
				position: absolute;
				bottom: -40px;
			}
		}
	}//main
	
	&__container{
		margin: 0 auto;
		padding-left:wCon(100,1280);
		padding-right:wCon(100,1280);
		max-width: 1080px;
		
		/*reason*/
		&__reason{
			margin-bottom: wCon(87,1080);
			@include media($tabletSize) {
				margin-bottom: wCon(57,375);
			}
			&__pic{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				@include media($tabletSize) {
					margin-bottom: 30px;
				}
				&__item{
					width: wCon(340,1080);
					margin-bottom: 50px;
					@include media($tabletSize) {
						width: 100%;
						margin-bottom: 20px;
						&:last-child{
							margin-bottom: 0;
						}
					}
					img {
						width: 100%;
						height: auto;
					}
				}
			}
			&__textBox{
					text-align: center;
				p{
					font-size: 18px;
					margin-bottom: 40px;
					@include media($tabletSize) {
						font-size: 13px;
						text-align: left;
						margin-bottom: wCon(22,375);
					}
				}
			}
			h4{
				font-size: 20px;
				text-align: center;
				margin-bottom:wCon(30,1080);
				font-weight: bold;
				@include media($tabletSize) {
					font-size: 14px;
					letter-spacing: -0.7px;
				}
			}
			a{
				width: 329px;
    			height: 60px;
    			line-height: 60px;
    			box-shadow: 2px 2px 4px grey;
    			box-sizing: border-box;
				@include media($tabletSize) {
					width: 240px;
					height: 35px;
    				line-height: 35px;
					box-shadow: 2px 2px 4px gray;
				}
			}
		}//reason
	}//container
	
	.top__container{
		@include media($tabletSize) {
			padding-left:wCon(20,375);
			padding-right:wCon(20,375);
		}
	}
	
	/*service*/
	.top__service{
		background: url(../img/top/top_bg_02.jpg);
		background-position: center center;
		background-size: cover;
		padding-top: wCon(45,1280);
		&__inner{
			margin: 0 auto;
			padding-left:wCon(100,1280);
			padding-right:wCon(100,1280);
			padding-bottom: wCon(55,1280);
			max-width: 1080px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			@include media($notepcSize) {
					max-width: 500px;
				}
			@include media($tabletSize) {
				padding-left:wCon(20,375);
				padding-right:wCon(20,375);
				padding-bottom: wCon(30,375);
			}
			&__list{
				width: wCon(255,1080);
				background: #fff;
				border: 1px solid;
				border-color: $mainColor;
				box-sizing: border-box;
				position: relative;
				&:before{
					content: "";
					display: inline-block;
					width: 0;
					height: 0;
					border-top: 15px solid;
					border-left: 15px solid;
					border-color: $mainColor;
					border-right: 15px solid transparent;
					border-bottom: 15px solid transparent;
					text-align: left;
					@include media($notepcSize) {
						content: "";
						display: inline-block;
						width: 0;
						height: 0;
						border-top: 11px solid;
						border-left: 11px solid;
						border-color: $mainColor;
						border-right: 11px solid transparent;
						border-bottom: 11px solid transparent;
						text-align: left;
					}
				}
				@include media($notepcSize) {
					width: wCon(390,810);
					margin-bottom: wCon(30,810);
					max-width: 250px;
				}
				&__inner{
					text-align: center;
					padding: 0px 20px 25px 20px;
					margin-top:-20px;
					@include media($notepcSize) {
						margin-top:-15px;
						padding-right: wCon(5,375);
						padding-left: wCon(10,375);
						padding-bottom: wCon(15,375);
						}
					h4{
						font-size: 22px;
						font-weight: bold;
						@include media($tabletSize) {
							font-size: 14px;
						}
						&:after{
							content: "";
							display: block;
							border-bottom: 1px solid;
							border-color: $mainColor;
							width: 50px;
							margin: 0 auto;
							padding-top: 5px;
						}
					}
					h4.service01:before{
						content: "";
						display: block;
						width: 45px;
						height: 36px;
						background: url(../img/top/top_icon_01.png) no-repeat;
						background-size: contain;
						vertical-align: middle;
						margin: 0 auto;
						padding-bottom: 10px;
						@include media($notepcSize) {
							width: 31px;
							height: 25px;
							padding-bottom: 5px;
							}
					}
					h4.service02:before{
						content: "";
						display: block;
						width: 45px;
						height: 36px;
						background: url(../img/top/top_icon_02.png) no-repeat;
						background-size: contain;
						vertical-align: middle;
						margin: 0 auto;
						padding-bottom: 10px;
						@include media($notepcSize) {
							width: 31px;
							height: 25px;
							padding-bottom: 5px;
							}
					}
					h4.service03:before{
						content: "";
						display: block;
						width: 45px;
						height: 36px;
						background: url(../img/top/top_icon_03.png) no-repeat;
						background-size: contain;
						vertical-align: middle;
						margin: 0 auto;
						padding-bottom: 10px;
						@include media($notepcSize) {
							width: 31px;
							height: 25px;
							padding-bottom: 5px;
							}
					}
					h4.service04:before{
						content: "";
						display: block;
						width: 45px;
						height: 36px;
						background: url(../img/top/top_icon_04.png) no-repeat;
						background-size: contain;
						vertical-align: middle;
						margin: 0 auto;
						padding-bottom: 10px;
						@include media($notepcSize) {
							width: 31px;
							height: 25px;
							padding-bottom: 5px;
							}
					}
					ul{
						padding-top: 15px;
						padding-bottom: 50px;
						text-align: left;
						list-style-position: inside;
						@include media($notepcSize) {
							padding: 10px;
							padding-bottom: 65px;
							}
						li{
							padding-left: 1em;
							text-indent: -1em;
							span{
								color: $mainColor;
								font-size: 12px;
								padding-right: 2px;
								@include media($notepcSize) {
									font-size: 10px;
								}
							}
						}
					}
					a{
						position: absolute;
						bottom:20px;
						left:0;
						right:0;
						width: 170px;
						height: 43px;
						margin: 0 auto;
						box-sizing: border-box;
						@include media($notepcSize) {
							position: absolute;
							bottom:15px;
							width: 107px;
							height: 26px;
							line-height: 26px;
							font-size: 11px;
							padding-left: 10px;
							}
					}
				}//inner
			}//list
		}//inner
	}//service
	
	/*news*/
	&__container{
		margin: 0 auto;
		padding-left: wCon(100,1280);
		padding-right: wCon(100,1280);
		max-width: 1080px;
		/*news*/
		&__news{
			margin-top: wCon(80,1080);
			margin-bottom: wCon(100,1080);
			text-align: center;
			@include media($tabletSize) {
				margin-bottom: wCon(45,375);
			}
			&__inner{
				margin: 0 auto;
				padding-bottom: wCon(50,1080);
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				@include media($mobileSize) {
					padding-bottom: 0;
				}
				&__list{
					width: wCon(340,1080);
					text-align: left;
					@include media($mobileSize) {
						width: 100%;
						margin-bottom: wCon(22,375);
					}
					.object-fit-img{
						object-fit: cover;
    					object-position: center center;
    					font-family: 'object-fit: cover;';
    					@include media($mobileSize) {
    					}
					}

					a{
						display: inline-block;
						width: 100%;
						&:hover{
							opacity: 0.5;
						}
						img{
							width: 100%;
							height: 227px;
    						object-fit: cover;
    						object-position: center center;
							margin-bottom: 10px;
							transition: 0.3s;
							@include media($navSize) {
								height: 185px;
							}
							@include media($notepcSize) {
								height: 168px;
							}
							@include media($tabletSize) {
							}
							@include media($mobileSize) {
								height: auto;
								max-width: 100%;
							}
							@include media($mobileSize_s) {
							}
						}
					}
				
					&__mark{
						float: left;
						margin-right: 10px;
						@include media($tabletSize) {
							font-size: 12px;
							width: 92px;
							height: 25px;
							line-height: 25px;
							margin-right: 10px;
						}
						a{
							display: inline-block;
							color: #FFFFFF;
    						background: $mainColor;
    						padding: 5px 10px;
    						width: auto;
						}
					}
					&__date{
						font-size: 13px;
						float: left;
						line-height: 29px;
						padding-top: 2px;
						@include media($tabletSize) {
							font-size: 12px;
							line-height: 25px;
						}
					}
					dt{
						font-weight: bold;
						font-size: 18px;
						padding-top:5px;
						padding-bottom: 2px;
						@include media($tabletSize) {
							font-size: 14px;
						}
					}
				}
			}
			.moreBtn{
				width: 329px;
				height: 60px;
				line-height: 60px;
				padding-left: 60px;
				box-shadow: 2px 2px 4px gray;
				box-sizing: border-box;
				&:after{
					margin: 6px 0 0 60px;
				}
				@include media($tabletSize) {
					height: 35px;
					line-height: 35px;
					width: 188px;
					padding-left: 30px;
					&:after{
					margin: 6px 0 0 20px;
					}
				}
			}
		}//news
		&__partner{
			margin-bottom: wCon(90,1080);
			border: 2px solid #333;
			box-sizing: border-box;
			@include media($tabletSize) {
					text-align: center;
					margin-bottom: wCon(50,375);
				}
			h4{
				font-size: 22px;
				color: #fff;
				font-weight: bold;
				line-height: 40px;
				text-align: center;
				background: #333;
				@include media($tabletSize) {
					font-size: 14px;
					line-height: 35px;
					}
				}
			img{
				width: wCon(448,1080);
				height: 100%;
				float: left;
				@include media($tabletSize) {
					width: 100%;
					float: none;
				}
			}
			p{
				width: wCon(602,1080);
				padding-top: wCon(15,1080);
				padding-left: wCon(15,1080);
				padding-right: wCon(15,1080);
				padding-bottom: wCon(15,1080);
				float: left;
				@include media($tabletSize) {
					width: 100%;
					float: none;
					box-sizing: border-box;
				}
			}
			.moreBtn{
				float: right;
				width: 210px;
				font-size: 14px;
				margin-right: wCon(10,620);
				margin-top: wCon(5,620);
				margin-bottom: wCon(10,620);
				cursor: pointer;
				@include media($tabletSize) {
					float: none;
					height: 35px;
					line-height: 35px;
					width: 188px;
					padding-left: 20px;
					font-size: 12px;
					margin-right: 0;
					margin-top: wCon(5,375);
					margin-bottom: wCon(15,375);
					&:after{
					margin: 6px 0 0 18px;
					}
				}
			}
		}//partner
	}//container
}//top
