/**************************************
reason.scss

***************************************/

.reason{
	.marginInner{
		margin: 0 auto;
		padding-left:wCon(100,1280);
		padding-right:wCon(100,1280);
		max-width: 1080px;
		@include media($tabletSize) {
			padding-left:wCon(20,375);
			padding-right:wCon(20,375);
		}
		
	}//common
	&__main{
		background-image: url(../img/reason/reason_bg_01.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;	
		width: 100%;
		height: 600px;
		position: relative;
		@include media($tabletSize) {
			height: 400px;
			margin-bottom: 10px;
			}
		h2{
			position: absolute;
			left: wCon(97,1280);
			bottom: wCon(157,1280);
			color:#fff;
			padding-right: wCon(97,1280);
			font-size: 52px;
			line-height: 1.3;
			letter-spacing:1.4;
			@include media($tabletSize) {
				font-size: 24px;
			}
			span{
				display: block;
				padding-bottom: 15px;
				@include media($tabletSize) {
				font-size: 12px;
				}
			}
		}
	}//main
	.here{
		margin-bottom: 70px;
		margin-top: wCon(10,1080);
		font-size: 12px;
		a{
			font-size: 12px;
		}
		
	}//common
	
	/*reason*/
	&__content{
		h3{
			margin-bottom: wCon(85,1280);
			@include media($tabletSize) {
				margin-bottom: wCon(40,375);
			}
		}
		&__nav{
			margin: 0 auto;
			margin-bottom: wCon(100,1080);
			max-width: 930px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			@include media($tabletSize) {
				max-width: 400px;
				}
			li{
				width:wCon(220,930);
				height: auto;
				text-align: center;
				@include media($tabletSize) {
					width:50%;
					padding-bottom: 35px;
				}
				h4{
					padding-bottom: 25px;
					@include media($tabletSize) {
						padding-bottom: 10px;
					}
				}
				p{
					padding-top: 30px;
					padding-bottom: 12px;
					font-weight: bold;
					font-size: 18px;
					@include media($tabletSize) {
						padding-top: 10px;
						padding-bottom: 6px;
						font-size: 12px;
					}
				}
				img{
					display: block;
					margin: 0 auto;
					@include media($tabletSize) {
							width: 35%;
							height: auto;
						}
				}
			}
		}//nav
		&__inner{
			background: #efefef;
			padding-top: wCon(57,1280);
			padding-bottom: wCon(57,1280);
			margin-bottom: wCon(60,1280);
			@include media($tabletSize) {
				padding-top: wCon(42,375);
			}
			ul{
				li{
					width: 100%;
					height: 400px;
					margin-bottom:160px;
					&:nth-child(1){
							background: url(../img/reason/reason_img_01.jpg) no-repeat left top;
							@include media($tabletSize) {
									background: none;
								}
							div{
								float: right;
								h4:before{
									background: url(../img/reason/reason_ttl_06.png) no-repeat;
									@include media($tabletSize) {
										background: url(../img/reason/reason_ttl_10.png) no-repeat;
									}
								}
							}
						}
						&:nth-child(2){
							background: url(../img/reason/reason_img_02.jpg) no-repeat right top;
							@include media($tabletSize) {
								background: none;
							}
							div{
								float: left;
								h4:before{
									background: url(../img/reason/reason_ttl_07.png) no-repeat;
									@include media($tabletSize) {
										background: url(../img/reason/reason_ttl_11.png) no-repeat;
									}
								}
							}
						}
						&:nth-child(3){
							background: url(../img/reason/reason_img_03.jpg) no-repeat left top;
							@include media($tabletSize) {
								background: none;
							}
							div{
								float: right;
								h4:before{
									background: url(../img/reason/reason_ttl_08.png) no-repeat;
									@include media($tabletSize) {
										background: url(../img/reason/reason_ttl_12.png) no-repeat;
									}
								}
							}
						}
						&:nth-child(4){
							background: url(../img/reason/reason_img_04.jpg) no-repeat right top;
							margin-bottom:0; 
							@include media($tabletSize) {
								background: none;
							}
							div{
								float: left;
								h4:before{
									background: url(../img/reason/reason_ttl_09.png) no-repeat;
									@include media($tabletSize) {
										background: url(../img/reason/reason_ttl_13.png) no-repeat;
									}
								}
							}
						}
					@include media($tabletSize) {
						width: 100%;
						height: auto;
						margin-bottom:0px;
					}
					img{
						width: 100%;
						height: auto;
					}
					div{
						width: 575px;
						height: 420px;
						background: #fff;
						margin-top: wCon(100,1080);
						box-sizing: border-box;
						padding: 0 15px 40px 0;
						@include media($tabletSize) {
							width: 100%;
							height: auto;
							margin-top:0;
							margin-bottom: 30px;
							float: none;
							padding: 0 12px 15px 0;
						}
						h4{
							font-size: 22px;
							font-weight: bold;
							line-height: 1.6;
							padding: 10px 0 40px 30px;
							@include media($tabletSize) {
								font-size: 14px;
								padding: 0px 0 15px 15px;
							}
							&:before{
								content: "";
								display: block;
								float: left;
								width: 94px;
								height: 64px;
								background: url(../img/reason/reason_ttl_06.png) no-repeat;
								margin: 5px 20px 0 0;
								@include media($tabletSize) {
									content: "";
									display: block;
									float: left;
									width: 55px;
									height: 38px;
									background: url(../img/reason/reason_ttl_06.png) no-repeat;
									margin: 5px 10px 0 0;
								}
							}
							span{
								color: $mainColor;
								font-weight:bold;
							}
						}//h4
						p{
							padding-left: 15px;
							font-size: 18px;
							a{
								color: $mainColor;
								border-bottom: 1px solid;
								border-bottom-color: $mainColor;
							}
							@include media($tabletSize) {
								font-size: 12px;
								padding-left: 12px;
							}
						}
						&:before{
							content: "";
							display: inline-block;
							width: 0;
							height: 0;
							border-top: 15px solid;
							border-left: 15px solid;
							border-color: $mainColor;
							border-right: 15px solid transparent;
							border-bottom: 15px solid transparent;
							text-align: left;
							@include media($tabletSize) {
								border-top: 10px solid;
								border-left: 10px solid;
								border-color: $mainColor;
								border-right: 10px solid transparent;
								border-bottom: 10px solid transparent;
							}
						}
						
					}//div
				}//li
			}//ul
		}//inner
	}//content

	/*voice*/
	&__voice{
		margin-bottom:wCon(85,1280);
		h3{
			margin-bottom: wCon(80,1080);
		}
		ul{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			li{
				width: wCon(500,1080);
				margin-bottom: wCon(60,1080);
				@include media($tabletSize) {
					width: 100%;
					margin-bottom: wCon(32,375);
				}
				.voicePerson{
					border-top: 1px solid;
					border-color: $mainColor;
					text-align: right;
					padding-top: 5px;
					margin-top: 5px;
					P{
					font-size: 18px;
						@include media($tabletSize) {
							font-size: 13px;
						}
					}
				}
			}
		}
	}//voice
	// #reason1,#reason2,#reason3,#reason4{
	// 	padding-top: 60px;
	// 	margin-top: -60px;
	// }
}//reason

