/**************************************

variables.scss

***************************************/
/* ---------------------------------------------------------
utility用
----------------------------------------------------------*/
$base-size: 16;

/* ---------------------------------------------------------
break point
----------------------------------------------------------*/
$wideSize: 1400px;
$pcSize: 1280px;
$navSize: 1040px;
$notepcSize: 960px;
$tabletSize: 768px;
$mobileSize: 640px;
$mobileSize_s: 400px;

/* ---------------------------------------------------------
color
----------------------------------------------------------*/
$accentColor: #ffffff;
$baseColor: #ffffff;
$mainColor: #2db4a9;

/* ---------------------------------------------------------
font family
----------------------------------------------------------*/
$fontFamilyBase: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS P Gothic', sans-serif;
$fontFamilyGoth: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS P Gothic', sans-serif;
$fontFamilyMin: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
// $fontFamilyGoth: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
// $fontFamilyMin: YuMincho,  "游明朝", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
