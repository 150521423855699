/**************************************

common.scss

***************************************/

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

.pcNone{
	display: none;
	@include media($tabletSize) {
		display: block;
	}	
}

.spNone{
	display: block;
	@include media($tabletSize) {
		display: none;
	}	
}


@include media($tabletSize) {
		p,h1,h2,h3,h4,h5,a,li,ul,small,dd,dt,a{
			font-size: 12px;
		}
	}

/*ttl*/

h2 span{
	font-size: 21px;
}

h3{
	font-size: 26px;
	text-align: center;
	margin-bottom:  wCon(40,1080);
	line-height: 50px;
	letter-spacing: 0.1em;
	font-weight: bold;
	span{
		line-height: 1.4;
		display: block;
		padding-top: 10px;
		font-weight: bold;
		@include media($tabletSize) {
			font-size: 15px;
		}
	}
	img{
		height: 37px;
		width: auto;
		@include media($tabletSize) {
			height: 25px;
		}
	}
}

/*btn*/

a.moreBtn{
	height: 42px;
	line-height: 42px;
	display: inline-block;
	color: #fff;
	background-color: $mainColor;
	font-weight: bold;
	text-align:center;
	transition: .3s;
	box-sizing: border-box;
}
a.moreBtn:after {
	content: "";
	display: inline-block;
	width: 16px;
	height: 20px;
	margin: 6px 0 0 20px;
	background: url(../img/common/common_icon_02.png) no-repeat;
	background-size: contain;
	vertical-align: middle;
}
a.moreBtn:hover {
    opacity: 0.5;
}

@include media($tabletSize) {
		a.moreBtn{
		height: 35px;
		line-height: 35px;
			&:after{
				content: "";
				display: inline-block;
				width: 12px;
				height: 15px;
				margin: 6px 0 0 20px;
				background: url(../img/common/common_icon_02.png) no-repeat;
				background-size: contain;
				vertical-align: middle;
			}
	}
}


/**************************************

header.scss

***************************************/
body{
	position: relative;
}

.header{
	background: rgba(255,255,255,0.8);
	height: 100px;
	z-index: 10;
	width: 100%;
	position: fixed;
	@include media($navSize) {
		height: 50px;
	}
	&__inner{
		margin:0 auto;
		max-width: 1280px;
		padding: 15px 25px;
		@include media($navSize) {
				padding: 0px 20px;
			}
		&__ttl{
			float: left;
			width: wCon(234,1280);
			padding-top: 20px;
			@include media($navSize) {
					padding-top: 14px;
				}
			img{
				width: 100%;
				vertical-align: middle;
				@include media($navSize) {
					width:162px;
				}
			}
		}
		&__tel{
			float: right;
			background: #2db4a9;
			padding: 5px 10px 15px 10px;
			padding: 5px 10px 10px 10px;
			border-radius: 5px;
			line-height: 1;
			@include media($navSize) {
				display: none;
			}
			&__telNumber{
				color: #fff;
				font-size: 20px;
				&:before {
				content: "";
				display: inline-block;
				width: 22px;
				height: 22px;
				padding-right: 3px;
				background: url(../img/common/common_icon_01.png) no-repeat;
				background-size: contain;
				vertical-align: text-top;
				}
			}
			&__telDate{
				color:#fff;
				font-size: 15px;
				text-align: center;
				line-height: 1.4;
			}
		}
		&__nav{
			float: right;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 60%;
			max-width: 630px;
			padding: 22px 20px 0 5px;
			@include media($navSize) {
				display: none;
			}
			li{
				/*float: left;*/
				padding: 0 5px 0 5px;
				a{
					font-size: 16px;
					font-weight: bold;
					position: relative;
  					display: inline-block;
					transition: .3s;
					&:hover{
					color: $mainColor;
					}
				}
				a::before{
					position: absolute;
					  bottom: -4px;
					  left: 0;
					  content: '';
					  width: 100%;
					  height: 2px;
					  background: $mainColor;
					  transform: scale(0, 1);
					  transform-origin: left top;
					  transition: transform .3s;
					
				}
				a:hover::before {
				  transform: scale(1, 1);
				}
			}//li
			li.serviceMenu{
				position: relative;
				a.serviceBtn:after{
					content: "▼";
					color: $mainColor;
					padding-left: 3px;
					}
				ul.serviceSub{
					z-index: 10;
					visibility: hidden;
					opacity: 0;
					position: absolute;
					top: 63px;
					left: 0;
					width: 105px;
					transition: .3s;
					padding-top:5px;
					margin-left: -10px;
					padding-left: 5px;
					background: rgba(255,255,255,0.8);
					li{
						float: none;
						margin: 10px 0px;
						box-sizing: border-box;
					}//li
				}//ul
				&:hover{
					.serviceSub{
						visibility: visible;
						opacity: 1;
					}
				}
			}//li
		}//nav	
	}//inner
}//header

/**************************************

スマホnav.scss

***************************************/

#nav-toggle {
	display: none;
	@include media($navSize) {
		display: block;
	}
    position: fixed;
    top: 15px;
    right: 25px;
    height: 60px;
    cursor: pointer;
}
    
#nav-toggle div {
    position: relative;
    width: 30px;
  }

#nav-toggle div span {
    width: 100%;
    height: 1.5px;
    left: 0;
    display: block;
    background-color: #333;
    position: absolute;
    transition: transform .3s ease-in-out, top .3s ease;
}
#nav-toggle .menu__line--top {
      top: 0;
    }
#nav-toggle .menu__line--center{
      top: 10px;
    }
#nav-toggle .menu__line--bottom{
      top: 20px;
    }

.open #nav-toggle .menu__line--top {
      top: 8px;
      transform: rotate(45deg);
}
.open #nav-toggle .menu__line--center {
      top: 8px;
      width: 0;
      left: 50%;
    }
.open #nav-toggle .menu__line--bottom {
      top: 8px;
      transform: rotate(-45deg);
    }

#nav-toggle {
    z-index: 1000;
}

#gloval-nav {
	margin-top: 50px;
    background: rgba(45,180,169,0.84);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 990;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    transition: opacity .8s ease, visibility .8s ease;
	padding: 20px 40px 40px 40px;
}

#gloval-nav a {
    display: block;
    color: #fff;
    text-decoration: none;
    padding: 20px;
    font-size: 16px;
	border-bottom: 1px solid #fff;
}

#gloval-nav ul li {
    opacity: 0;
	position: relative;
}
      

#gloval-nav ul li a:after{
	content:"";
	background: url(../img/common/common_icon_06.png) no-repeat;
	position: absolute;
	top: 28px;
	right:20px;
	display: inline-block;
	width: 8px;
	height: 20px;
}

/* open */
.open #gloval-nav {
    visibility: visible;
    opacity: 1;
  }

.open #gloval-nav li {
    opacity: 1;
  }


/**************************************

footer.scss

***************************************/

.footer{
	
	.topBtnBox{
		padding-left: wCon(100,1280);
		padding-right: wCon(100,1280);
		margin: 0 auto;
		max-width: 1080px;
		height: 60px;
	}
	.topBtn{
		font-size: 14px;
		font-weight: bold;
		float: right;
		line-height: 30px;
		&:after {
			content: "";
			display: inline-block;
			width: 30px;
			height: 30px;
			margin: 0px 0 0 5px;
			background: url(../img/common/common_icon_07.png) no-repeat;
			background-size: contain;
			vertical-align: middle;
		}
	}
	&__contact{
		padding-bottom:wCon(64,1280);
		padding-top: wCon(30,1280);
		text-align: center;
		background-color: rgba(45,180,169,0.35);
		@include media($notepcSize) {
			padding-bottom:wCon(32,375);
			}
		&__ttl{
			font-size: 22px;
			margin-bottom: 25px;
			@include media($notepcSize) {
				margin-bottom: 15px;
			}
		}
		&__txt{
			margin-bottom: 30px;
			@include media($notepcSize) {
				padding-left:wCon(20,375);
				padding-right:wCon(20,375);
				font-size: 14px;
				margin-bottom: 20px;
			}
		}
		&__inner{
			margin: 0 auto;
			padding-left:wCon(100,1280);
			padding-right:wCon(100,1280);
			/*padding-bottom: wCon(55,1280);*/
			max-width: 934px;
			@include media($notepcSize) {
				padding-left:wCon(20,375);
				padding-right:wCon(20,375);
				}
			.contactBtn{
				width: wCon(490,1006);
				height: 98px;
				display: block;
				color: $mainColor;
				border:2px solid;
				border-color: $mainColor;
				background-color: #fff;
				font-weight: bold;
				text-align:center;
				transition: .3s;
				box-sizing: border-box;
				position: relative;
				@include media($notepcSize) {
					width: 100%;
					height: 67.5px;
					max-width: 490px;
					margin: 0 auto;
					font-size:13px;
				}
				span{
					display: block;
					font-size: 24px;
					@include media($notepcSize) {
						font-size: 17px;
					}
				}
				span:after {
					content: "";
					display: inline-block;
					width: 25px;
					height: 16px;
					position: absolute;
					top: 40px;
					right: 10px;
					background: url(../img/common/common_icon_03.png) no-repeat;
					background-size: contain;
					@include media($notepcSize) {
						width: 18px;
						height: 22px;
						position: absolute;
						top: 27px;
						right: 10px;
					}
				}
			}
			.contactBtn:hover {
				opacity: 0.5;
			}
			.telBtn{
				float: left;
				padding-top:13px;
				span:before {
				content: "";
				display: inline-block;
				width: 25px;
				height: 30px;
				padding-right: 10px;
				background: url(../img/common/common_icon_04.png) no-repeat;
				background-size: contain;
				vertical-align: sub;
				}
				@include media($notepcSize) {
					float: none;
					margin-bottom: 20px;
					padding-top:4px;
				}
			}
			.mailBtn{
				float: right;
				line-height: 98px;
				span:before {
				content: "";
				display: inline-block;
				width: 25px;
				height: 28px;
				padding-right: 10px;
				background: url(../img/common/common_icon_05.png) no-repeat;
				background-size: contain;
				vertical-align: sub;
				}
				@include media($notepcSize) {
					float: none;
					line-height: 67.5px;
				}
			}
		}//inner
	}//contact
	&__nav{
		background: url(../img/common/common_bg_01.jpg) no-repeat;
		background-size: cover;
		height: wCon(350,1280);
		background-position: center top;
		padding-bottom: wCon(18,1280);
		@include media($navSize) {
			
		}
		&__inner{
			margin: 0 auto;
			padding-left:wCon(70,1280);
			padding-right:wCon(40,1280);
			padding-top: wCon(60,1280);
			padding-bottom: wCon(115,1280);
			max-width: 950px;
			@include media($navSize) {
				padding-top: wCon(220,1280);
				padding-bottom: wCon(175,1280);
			}
			&__left{
				float: left;
				@include media($navSize) {
					width: 100%;
				}
				p{
					color: #fff;
					padding-bottom: 15px;
					@include media($navSize) {
						font-size: 16px;
						text-align: center;
						padding-bottom: 0;
					}
					span{
						@include media($navSize) {
							display: none;
						}
					}
				}
			}
			&__right{
				width: wCon(690,950);
				float: right;
				@include media($navSize) {
					display: none;
				}
				li{
					float: left;
					padding-left: wCon(18,706);
					padding-right: wCon(18,706);
					a{
					color: #fff;
					font-weight: bold;
					position: relative;
						display: inline-block;
					}
					a::before{
						position: absolute;
						  bottom: -4px;
						  left: 0;
						  content: '';
						  width: 100%;
						  height: 2px;
						  background: #fff;
						  transform: scale(0, 1);
						  transform-origin: left top;
						  transition: transform .3s;
					}
					a:hover::before {
					  transform: scale(1, 1);
					}
					.navService li{
						float: none;
						padding:10px 0 0 0;
						font-size: 14px;
						font-weight: normal;
						margin-left: -8px;
					}
				}
			}
		}
		small{
			color: #fff;
			font-size: 10px;
			display: block;
			text-align: center;
		}
	}//nav
	
}//footer




